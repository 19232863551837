/* storybook-check-ignore */
import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { useAuth } from '@opendoor/auth-fe';
import { eero } from '@opendoor/bricks/theme/eero';

import { getExperimentVariant } from 'components/api/experiments';
import { useCurrentCustomer } from 'components/helpers/hooks';

import { useObservability } from '../../helpers/observability';
import { Lead, useLeads } from '../../helpers/useLeads';
import { getSessionExtras } from '../api';

const MenuItem = styled.a`
  :hover {
    color: ${eero.colors.brand50};
  }
  position: relative;
  color: #525975;
`;

const screenXsMax = 768 - 1;
const screenXssMax = 480 - 1;

const PrimaryNavSubmenu = styled.ul<{ isExpanded: boolean }>`
  position: absolute;
  top: 75px;
  right: 25px;
  padding: 25px;
  background: #ffffff;
  border-radius: 3px;
  text-align: left;
  min-width: max-content;
  ${(props) =>
    !props.isExpanded && `visibility: hidden; opacity: 0;`} -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  box-shadow: -5px 0 40px rgba(0, 0, 0, 0.1);
  margin: 0 0 0 -50px;
  @media (max-width: 1201px) {
    right: 20px;
  }
  @media (max-width: 767px) {
    right: 10px;
    left: inherit;
  }
  @media (max-width: 479px) {
    margin: 0;
    width: 120px;
  }
  :before {
    content: '';
    position: absolute;
    top: -7px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    z-index: 4;
  }
`;

const PrimaryNavItem = styled.li<{ height: string }>`
  position: relative;
  height: ${(props) => props.height};
  align-items: center;
  line-height: 25px;
  list-style: none;

  @media (max-width: ${screenXsMax}px) {
    margin-left: 12px;
    height: auto;
  }

  @media (max-width: ${screenXssMax}px) {
    margin-left: 6px;
    height: auto;
  }
`;

interface AccountMenuProps {
  isExpanded: boolean;
}

const AccountMenu = (props: AccountMenuProps) => {
  const { trackEvent } = useObservability();
  const { user: human, logout } = useAuth();
  const [hasResaleDashboard, setHasResaleDashboard] = useState(false);
  const [isHomeGuideTreatment, setIsHomeGuideTreatment] = useState(false);
  const leads = useLeads();
  const { customer: currentCustomer } = useCurrentCustomer();
  const eligibleForHomeGuide = isHomeGuideTreatment && !currentCustomer?.isAgent;

  useEffect(() => {
    if (!human) {
      return;
    }
    getSessionExtras().then((extras) => {
      setHasResaleDashboard(extras.has_resale_closing_dashboard);
    });
    getExperimentVariant({
      key: 'growth-24-home-guide-enrollment',
      customerUUID: human.customerUuid || '',
      autoImpression: false,
      defaultValue: 'control',
    }).then((variant) => variant && setIsHomeGuideTreatment(variant === 'treatment'));
  }, [human?.humanToken, human?.customerUuid]);

  const handleSignOutClick = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'account-signout', undefined, { categoryOverride: 'homepage' });
    logout();
  };

  return (
    <PrimaryNavSubmenu isExpanded={props.isExpanded}>
      {leads &&
        leads.slice(0, 3).map((lead: Lead) => (
          <PrimaryNavItem height={'25px'} key={lead.id} className="primary-nav-submenu-item">
            <MenuItem href={lead.url}>
              {lead.address.street}
              {lead.readable_offer_state && ` (${lead.readable_offer_state})`}
            </MenuItem>
          </PrimaryNavItem>
        ))}
      {leads && leads.length > 3 && (
        <PrimaryNavItem height={'25px'} className="primary-nav-submenu-item">
          <MenuItem href="/properties">More</MenuItem>
        </PrimaryNavItem>
      )}
      {leads && leads.length > 0 && <hr />}
      {hasResaleDashboard && (
        <PrimaryNavItem height={'25px'} className="primary-nav-submenu-item">
          <MenuItem href="/closing-dashboard">Closing dash</MenuItem>
        </PrimaryNavItem>
      )}
      {hasResaleDashboard && <hr />}
      {eligibleForHomeGuide && (
        <PrimaryNavItem height={'25px'} className="primary-nav-submenu-item">
          <MenuItem href="/home-insights">Home insights</MenuItem>
        </PrimaryNavItem>
      )}
      {eligibleForHomeGuide && <hr />}
      <PrimaryNavItem height={'25px'} className="primary-nav-submenu-item">
        <MenuItem
          // TODO: remove categoryOverride
          // observability is initialized with 'cosmos' as the category for all events
          onClick={() =>
            trackEvent('cta-click', 'account-link', undefined, { categoryOverride: 'homepage' })
          }
          href="/account"
        >
          Account
        </MenuItem>
      </PrimaryNavItem>
      <PrimaryNavItem height={'25px'} className="primary-nav-submenu-item">
        <MenuItem href="#" onClick={handleSignOutClick}>
          Sign out
        </MenuItem>
      </PrimaryNavItem>
    </PrimaryNavSubmenu>
  );
};

export default AccountMenu;
