import { globalObservability } from '@opendoor/observability/slim';

import {
  GetVariantRequestQueryVariables,
  SetImpressionMutationVariables,
} from '__generated__/athena';

import { getAnonymousId } from '../shared/trackers';
import { getAthenaClient } from './';

const Sentry = globalObservability.getSentryClient();

type GetExperimentVariantArgs = Omit<GetVariantRequestQueryVariables, 'grain'> & {
  defaultValue?: string;
  customerUUID?: string;
};

export async function getExperimentVariant(opts: GetExperimentVariantArgs) {
  const { key, autoImpression, customerUUID, props, defaultValue = 'control' } = opts;
  try {
    const anonymousId = getAnonymousId();

    const response = await getAthenaClient().GetVariantRequest({
      grain: {
        grain: {
          customer: {
            primaryId: { customerUuid: customerUUID ? customerUUID : null },
            temporaryId: { anonymousId: anonymousId ? anonymousId : null },
          },
        },
      },
      key,
      autoImpression,
      props,
    });

    return response?.variant?.getVariant?.variant?.value ?? defaultValue;
  } catch (e) {
    Sentry.captureException?.(e);
    return;
  }
}

type SetExperimentImpressionArgs = Omit<SetImpressionMutationVariables, 'grain'> & {
  customerUUID?: string;
};

// Call `setExperimentImpression` when the user gets exposed to the experiment variant if this happens
// at some point after bucketing (i.e., `getExperimentVariant` was not called with `{autoImpression: true}`)
export async function setExperimentImpression({
  key,
  variantValue,
  customerUUID,
}: SetExperimentImpressionArgs) {
  try {
    const anonymousId = getAnonymousId();

    await getAthenaClient().SetImpression({
      grain: {
        grain: {
          customer: {
            primaryId: { customerUuid: customerUUID ? customerUUID : null },
            temporaryId: { anonymousId: anonymousId ? anonymousId : null },
          },
        },
      },
      key,
      variantValue,
    });
  } catch (e) {
    Sentry.captureException?.(e);
  }
}
